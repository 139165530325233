import { Switch, Route, Redirect } from "react-router-dom";
//Components
import Sidebar from "../../components/Sidebar/Sidebar";
//Pages
import Announcement from "../Announcement/Announcement";

function Dashboard(props) {
  return (
    <>
      <div className="bg-gray-200 h-full w-full absolute"></div>
      <Sidebar setIsLoggedIn={props.setIsLoggedIn} />
      <div
        className="relative flex flex-col ml-0 sm:ml-14 lg:ml-64 h-screen"
        style={{ height: "calc(var(--vh, 1vh) * 100)" }}
      >
        <div className="flex-grow bg-gray-200 pb-10">
          <Switch>
            <Route path="/announcements" exact component={Announcement} />
            <Redirect path="*" to="/announcements" />
          </Switch>
        </div>
        {/* Footer */}
        <div className="border-t-2 bg-gray-100">
          <h2 className="text-center p-3 text-gray-400">© 2021 aperQ</h2>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
