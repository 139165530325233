const firebaseConfig = {
  apiKey: "AIzaSyCZKrsH_P8kADvSRT5G8RzkjdMmqJxsBA8",
  authDomain: "aperq-ccb29.firebaseapp.com",
  databaseURL:
    "https://aperq-ccb29-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "aperq-ccb29",
  storageBucket: "aperq-ccb29.appspot.com",
  messagingSenderId: "966724468939",
  appId: "1:966724468939:web:82dd2fbcbe00d69a9c1b4e",
  measurementId: "G-SFTM2CYF5E",
};

export default firebaseConfig;
